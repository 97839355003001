// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Text,
  Img,
  Button,
  Fade,
  WrapperOptions,
  Modal,
} from '@monoku/ns-shared-components';

// Utils
import { getMediaURL } from 'utils';

import LayoutPoll from 'components/Layout/PollLayout';

// Assets
import Logo from 'assets/images/logo.svg';
import iconCheck from 'assets/images/check.svg';

// Styles
import styles from './Main.module.css';

const WRAPPER_WIDTH = 400;
const WRAPPER_HEIGHT = 400;
const BALL_SIZE = 20;

const Main = ({ context, matches, send }) => {
  const { pollData, errorMessage } = context;

  const choices = pollData.choices.map((option) => ({ ...option, iconCheck }));

  useEffect(() => {
    document.body.style.backgroundImage = `url(${getMediaURL(
      pollData.background_image_desktop
    )})`;
  }, [pollData]);

  const retryAction = () => {
    send('SELECT_ANSWER');
  };

  return (
    <LayoutPoll>
      <div className={styles.containerModal}>
        <Modal
          width="336px"
          height="322px"
          isOpen={matches('choicesView.loaded.error')}
          isMobile={false}
          title={errorMessage}
          onPress={retryAction}
        />
      </div>
      <picture className={styles.logo}>
        <Img src={Logo} alt="New Stand logo" width="75" height="75" />
      </picture>
      <div className={styles.contentTitle}>
        <Text text={pollData.title} variant="tiempos" color="white" />
      </div>
      <div className={styles.contentSubtitle}>
        <Text
          text={pollData.description}
          variant="sofiaProRegular"
          color="white"
        />
      </div>
      <section className={styles.wrapperOptions}>
        <div className={styles.contentBody}>
          <Text
            text={pollData.options_title}
            variant="sofiaProBold"
            color="white"
          />
        </div>
        <div className={styles.options}>
          <WrapperOptions
            width={BALL_SIZE}
            height={BALL_SIZE}
            parentHeight={WRAPPER_HEIGHT}
            parentWidth={WRAPPER_WIDTH}
            scaleAnimation={5}
            scaleContent={2}
            balls={choices}
            onChange={(option) => send('SELECT_ANSWER', { data: option })}
          />
        </div>
      </section>
      <Fade
        show={!matches('choicesView.loaded.waitingAnswer')}
        className={styles.confirmBtn}
      >
        <Button
          type="default"
          disabled={
            matches('choicesView.loaded.submitting') ||
            matches('choicesView.loaded.waitingAnswer')
          }
          onPress={() => send('SUBMIT_ANSWER')}
        >
          {matches('choicesView.loaded.submitting') ? 'SENDING...' : 'CONFIRM'}
        </Button>
      </Fade>
    </LayoutPoll>
  );
};

Main.propTypes = {
  context: PropTypes.shape({
    pollData: PropTypes.object,
    errorMessage: PropTypes.string,
  }).isRequired,
  matches: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
};

export default Main;
