import { Machine, assign } from 'xstate';
import { get, orderBy } from 'lodash-es';

import { isEmail } from 'utils';

import { getPoll, answerPoll, submitEmail } from 'services/poll';

import { askForEmail } from './askForEmail';
import { choices } from './choices';

export const initialContext = {
  pollId: null,
  pollData: {
    choices: [],
  },
  answer: {},
  results: {},
  email: '',
  askForEmail: true,
  errorMessage: '',
};

// ._ Actions
const onSuccessPoll = assign({
  pollData: (ctx, event) => get(event, 'data.data', event.data),
});

const onError = assign({
  errorMessage: (ctx, event) =>
    get(event, 'data.response.data.error[0]', 'Something went wrong'),
});

const onErrorSubmitEmail = assign({
  errorMessage: (ctx, event) => {
    const generalError = get(
      event,
      'data.response.data.error[0]',
      'Something went wrong'
    );

    const emailError = get(event, 'data.response.data.email[0]');

    return emailError || generalError;
  },
});

const setAnswer = assign({ answer: (ctx, event) => event.data });

const setEmail = assign({ email: (ctx, event) => event.email });

const onSuccessAnswer = assign({
  results: (ctx, event) => {
    const response = get(event, 'data.data', event.data);

    return {
      ...response,
      results: orderBy(response.results, ['percentage'], ['desc']),
    };
  },
});

const isValidEmail = (ctx, event) => isEmail(event.email);
const shouldAskForEmail = (ctx) => ctx.askForEmail;

export const singlePollMachine = Machine(
  {
    id: 'singlePoll',
    initial: 'choicesView',
    context: initialContext,
    states: {
      choicesView: {
        ...choices,
      },
      askForEmailView: {
        ...askForEmail,
      },
      resultsView: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      onSuccessPoll,
      onError,
      onErrorSubmitEmail,
      setAnswer,
      setEmail,
      onSuccessAnswer,
    },
    guards: {
      shouldAskForEmail,
      isValidEmail,
    },
    services: {
      getPoll,
      answerPoll,
      submitEmail,
    },
  }
);
