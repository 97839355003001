import React from 'react';

import Router from 'config/Router';

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
