export const askForEmail = {
  initial: 'waitingForEmail',
  states: {
    waitingForEmail: {
      on: {
        TYPING_EMAIL: [
          {
            target: 'emailValid',
            actions: ['setEmail'],
            cond: 'isValidEmail',
          },
          {
            target: 'emailInvalid',
            actions: ['setEmail'],
          },
        ],
      },
    },
    emailValid: {
      on: {
        SUBMIT_EMAIL: 'submitting',
        TYPING_EMAIL: [
          {
            target: 'emailValid',
            actions: ['setEmail'],
            cond: 'isValidEmail',
          },
          {
            target: 'emailInvalid',
            actions: ['setEmail'],
          },
        ],
      },
    },
    emailInvalid: {
      on: {
        TYPING_EMAIL: [
          {
            target: 'emailValid',
            actions: ['setEmail'],
            cond: 'isValidEmail',
          },
          {
            target: 'emailInvalid',
            actions: ['setEmail'],
          },
        ],
      },
    },
    submitting: {
      invoke: {
        src: 'submitEmail',
        onDone: '#singlePoll.resultsView',
        onError: {
          target: 'error',
          actions: ['onErrorSubmitEmail'],
        },
      },
    },
    error: {
      on: {
        CLOSE_MODAL: 'emailValid',
        SUBMIT: 'submitting',
        TYPING_EMAIL: [
          {
            target: 'emailValid',
            actions: ['setEmail'],
            cond: 'isValidEmail',
          },
          {
            target: 'emailInvalid',
            actions: ['setEmail'],
          },
        ],
      },
    },
  },
};

export default { askForEmail };
