import React from 'react';
import PropTypes from 'prop-types';

import styles from './PollLayout.module.css';

const PollLayout = ({ children, overlay }) => {
  return (
    <main className={styles.container}>
      <div style={{ backgroundImage: overlay }} className={styles.overlay} />
      <div className={styles.content}>
        <div className={styles.wrapper}>{children}</div>
      </div>
    </main>
  );
};

PollLayout.defaultProps = {
  overlay: 'linear-gradient(156deg, #5800fc 17%, #1694b1 68%)',
};

PollLayout.propTypes = {
  children: PropTypes.node.isRequired,
  overlay: PropTypes.string,
};

export default PollLayout;
