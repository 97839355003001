import * as urls from 'data/urls';
import API from 'data/API';

import getPollMock from '__mocks__/get_poll';
import answerPollMock from '__mocks__/answer_poll';
import submitEmailMock from '__mocks__/submit_email';

export const getPoll = (context) => {
  return API(
    {
      url: urls.getPoll(context.pollId),
    },
    {
      useMock: false,
      responseWith: getPollMock,
    }
  );
};

export const answerPoll = (context) => {
  const {
    pollId,
    answer: { uuid },
  } = context;

  return API(
    {
      url: urls.answerPoll(pollId),
      method: 'POST',
      data: {
        choices: [uuid],
      },
    },
    {
      useMock: false,
      responseWith: answerPollMock,
    }
  );
};

export const submitEmail = (context) => {
  const {
    // eslint-disable-next-line camelcase
    results: { result_uuid },
    pollId,
    email,
  } = context;

  return API(
    {
      url: urls.submitEmail(pollId),
      method: 'PUT',
      data: {
        result_uuid,
        email,
      },
    },
    {
      useMock: false,
      statusCode: 204,
      responseWith: submitEmailMock,
    }
  );
};
