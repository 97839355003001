export const choices = {
  initial: 'loading',
  states: {
    loading: {
      invoke: {
        src: 'getPoll',
        onDone: {
          target: 'loaded',
          actions: ['onSuccessPoll'],
        },
        onError: {
          target: 'error',
          actions: ['onError'],
        },
      },
    },
    loaded: {
      initial: 'waitingAnswer',
      states: {
        waitingAnswer: {
          on: {
            SELECT_ANSWER: {
              target: 'selectedAnswer',
              actions: ['setAnswer'],
            },
          },
        },
        selectedAnswer: {
          on: {
            SELECT_ANSWER: {
              target: 'selectedAnswer',
              actions: ['setAnswer'],
            },
            SUBMIT_ANSWER: 'submitting',
          },
        },
        submitting: {
          invoke: {
            src: 'answerPoll',
            onDone: [
              {
                target: '#singlePoll.askForEmailView',
                cond: 'shouldAskForEmail',
                actions: ['onSuccessAnswer'],
              },
              {
                target: '#singlePoll.resultsView',
                actions: ['onSuccessAnswer'],
              },
            ],
            onError: {
              target: 'error',
              actions: ['onError'],
            },
          },
        },
        error: {
          on: {
            CLOSE_MODAL: 'selectedAnswer',
            SUBMIT_ANSWER: 'submitting',
          },
        },
      },
    },
    error: {
      on: {
        RETRY_GET_POLL: 'loading',
      },
    },
  },
};

export default { choices };
