import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Text, Img, Button } from '@monoku/ns-shared-components';

import LayoutPoll from 'components/Layout/PollLayout';

// Assets
import Logo from 'assets/images/logo.svg';
import Background from 'assets/images/background_desktop.jpg';

import styles from './NotFound.module.css';

const NotFound = ({ errorMessage, canReload }) => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${Background})`;
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <LayoutPoll overlay="linear-gradient(121deg, #2a1257 8%, #28383b 64%)">
      <picture className={styles.logo}>
        <Img src={Logo} alt="New Stand logo" width="75" height="75" />
      </picture>
      <div className={styles.contentSubTitle}>
        <Text text="Oops!" variant="sofiaProBold" color="white" />
      </div>
      <div className={styles.contentTitle}>
        <Text text={errorMessage} variant="tiempos" color="white" />
      </div>
      {canReload && (
        <div className={styles.contentButton}>
          <Button type="primary" onPress={reloadPage}>
            RELOAD
          </Button>
        </div>
      )}
    </LayoutPoll>
  );
};

NotFound.defaultProps = {
  canReload: false,
  errorMessage: 'Sorry, this poll is not available at the moment',
};

NotFound.propTypes = {
  errorMessage: PropTypes.string,
  canReload: PropTypes.bool,
};

export default NotFound;
