import React from 'react';
import PropTypes from 'prop-types';
// Utils
import { Img, Text, WrapperOptions } from '@monoku/ns-shared-components';

import LayoutPoll from 'components/Layout/PollLayout';

// Assets
import Logo from 'assets/images/logo.svg';

import styles from './Result.module.css';

const Result = ({ context }) => {
  const {
    pollData,
    results: { results, tie },
  } = context;
  const WRAPPER_WIDTH = 400;
  const WRAPPER_HEIGHT = 400;
  const BALL_SIZE = 20;

  return (
    <LayoutPoll overlay="linear-gradient(48deg, #609dbc 23%, #94603d 82%)">
      <picture className={styles.logo}>
        <Img src={Logo} width="75" height="75" />
      </picture>
      <div className={styles.contentTitle}>
        <Text text={pollData.title} variant="tiempos" color="white" />
      </div>
      <div className={styles.contentSubtitle}>
        <Text
          text={pollData.description}
          variant="sofiaProRegular"
          color="white"
        />
      </div>
      <div className={styles.containerOptions}>
        <WrapperOptions
          balls={results}
          width={BALL_SIZE}
          height={BALL_SIZE}
          parentHeight={WRAPPER_HEIGHT}
          parentWidth={WRAPPER_WIDTH}
          labelOptionSize="1.25rem"
          shouldAnimateWinner={!tie}
          type="result"
        />
      </div>
    </LayoutPoll>
  );
};

Result.propTypes = {
  context: PropTypes.shape({
    pollData: PropTypes.object,
    results: PropTypes.shape({
      results: PropTypes.array,
      tie: PropTypes.bool,
    }),
  }).isRequired,
};

export default Result;
