const {
  REACT_APP_STAGE,
  REACT_APP_STAGING_API_URL,
  REACT_APP_PRODUCTION_API_URL,
} = process.env;

const stage = REACT_APP_STAGE;

let REACT_APP_API_URL;

if (stage === 'staging') {
  REACT_APP_API_URL = REACT_APP_STAGING_API_URL;
} else {
  REACT_APP_API_URL = REACT_APP_PRODUCTION_API_URL;
}

const env = {
  REACT_APP_API_URL,
};

export default env;
