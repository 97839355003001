import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import SinglePoll from 'pages/SinglePoll';
import NotFound from 'pages/NotFound';

const routes = [
  {
    path: '/single-choice/:id',
    exact: true,
    component: SinglePoll,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const Router = () => (
  <BrowserRouter>
    <Switch>
      {routes.map((route, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  </BrowserRouter>
);

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export default Router;
