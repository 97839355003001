import React from 'react';
import PropTypes from 'prop-types';
import { Text, Img, Input, Button, Fade } from '@monoku/ns-shared-components';

import LayoutPoll from 'components/Layout/PollLayout';
// Utils
import { getCopy } from 'utils/copies';
// Assets
import Logo from 'assets/images/logo.svg';

import styles from './SubmitEmail.module.css';

const SubmitEmail = ({ context, matches, send }) => {
  const { email, errorMessage } = context;

  return (
    <LayoutPoll>
      <div className={styles.container}>
        <picture className={styles.logo}>
          <Img alt="New Stand Logo" src={Logo} width="75" height="75" />
        </picture>
        <div className={styles.contentTitle}>
          <Text text={getCopy('email.title')} variant="tiempos" color="white" />
        </div>
        <div className={styles.contentSubtitle}>
          <Text
            text={getCopy('email.subtitle')}
            variant="sofiaProRegular"
            color="white"
          />
        </div>
        <form
          className={styles.wrapperForm}
          onSubmit={(e) => {
            e.preventDefault();
            send('SUBMIT_EMAIL');
          }}
        >
          <Input
            className={styles.input}
            placeholder="myemail@mail.com"
            value={email}
            onChange={(e) => send('TYPING_EMAIL', { email: e.target.value })}
          />
          <Fade
            show={matches('askForEmailView.emailInvalid')}
            className={styles.inputError}
          >
            <Text text={errorMessage} variant="sofiaProRegular" color="white" />
          </Fade>
          <div className={styles.contentButton}>
            <Button
              type="secundary"
              disabled={
                matches('askForEmailView.emailInvalid') ||
                matches('askForEmailView.submitting')
              }
              onPress={() => send('SUBMIT_EMAIL')}
            >
              {matches('askForEmail.submitting')
                ? getCopy('email.submitting')
                : getCopy('email.submit')}
            </Button>
          </div>
        </form>
      </div>
    </LayoutPoll>
  );
};

SubmitEmail.propTypes = {
  context: PropTypes.shape({
    email: PropTypes.string,
    errorMessage: PropTypes.string,
  }).isRequired,
  matches: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
};
export default SubmitEmail;
