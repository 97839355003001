import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { Fade, Modal } from '@monoku/ns-shared-components';

import Loader from 'components/Loader';
import NotFoundPage from 'pages/NotFound';

import { singlePollMachine, initialContext } from './Machine';
import Main from './views/Main';
import Result from './views/Result';
import SubmitEmail from './views/SubmitEmail';

import styles from './SinglePoll.module.css';

const SinglePoll = () => {
  const { id } = useParams();
  const [state, send] = useMachine(
    singlePollMachine.withContext({
      ...initialContext,
      pollId: id,
    })
  );
  const { errorMessage } = state.context;

  return state.matches('choicesView.error') ? (
    <NotFoundPage canReload />
  ) : (
    <div className={styles.container}>
      <Modal
        widthModal="336px"
        heightModal="auto"
        minHeightModal="322px"
        zIndex={10}
        isOpen={
          state.matches('choicesView.loaded.error') ||
          state.matches('askForEmailView.error')
        }
        isMobile={false}
        title={errorMessage}
        onPress={() => send('CLOSE_MODAL')}
      />
      <Fade
        className={cn(styles.content, styles.loader)}
        show={state.matches('choicesView.loading')}
      >
        <Loader />
      </Fade>
      <Fade
        className={styles.content}
        show={state.matches('choicesView.loaded')}
      >
        <Main context={state.context} matches={state.matches} send={send} />
      </Fade>
      <Fade className={styles.content} show={state.matches('askForEmailView')}>
        <SubmitEmail
          context={state.context}
          matches={state.matches}
          send={send}
        />
      </Fade>
      <Fade show={state.matches('resultsView')}>
        <Result className={styles.content} context={state.context} />
      </Fade>
    </div>
  );
};

export default SinglePoll;
