import env from 'env';

export const isEmail = (email) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  if (email && typeof email === 'string') {
    return regex.test(email);
  }

  return false;
};

export const getMediaURL = (mediaURL = '') => {
  if (mediaURL && mediaURL.includes(env.REACT_APP_API_URL)) {
    return mediaURL;
  }

  return `${env.REACT_APP_API_URL}/media/${mediaURL}`;
};

export default { isEmail };
